import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import Event from '../../../globals/interfaces/event';
import EventCard from '../EventCard/EventCard';

import useStyles from './EventList.styles';

const EventList = (): JSX.Element => {
  const classes = useStyles();

  const [loading, setLoading] = React.useState(true);
  const [events, setEvents] = React.useState<Event[]>([]);

  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          backendUrl
        }
      }
    }
  `);

  const { backendUrl } = site.siteMetadata;

  const fetchEvents = React.useCallback(async () => {
    try {
      const response = await fetch(`${backendUrl}/api/events`);
      const events: Event[] = await response.json();

      setEvents(events);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [backendUrl]);

  React.useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  return (
    <>
      <Typography variant="h4" style={{ color: '#353b48', marginBottom: 32 }}>
        Events
      </Typography>
      <div
        className={classes.eventsContainer}
        style={{
          justifyContent: loading ? 'center' : 'flex-start',
        }}
      >
        {loading && <CircularProgress size={96} variant="indeterminate" />}
        {!loading && events.length === 0 && (
          <Typography variant="h4">No Events Found</Typography>
        )}
        <Grid spacing={2} container>
          {events.map((event) => (
            <EventCard key={event.id} {...event} />
          ))}
        </Grid>
      </div>
    </>
  );
};

export default EventList;
