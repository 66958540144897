import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import Event from '../../../globals/interfaces/event';

import useStyles from './EventCard.styles';

interface Props extends Event {}

interface EBOptions {
  widgetType: 'checkout';
  eventId: string;
  modal: boolean;
  modalTriggerElementId: string;
  onOrderComplete?: (params: unknown) => void;
}

interface EBWidgets {
  createWidget: (options: EBOptions) => void;
}

const windowGlobal = typeof window !== 'undefined' && window;
const ebWidgets = windowGlobal && (windowGlobal['EBWidgets'] as EBWidgets);

const EventCard = ({
  name,
  description,
  logo,
  capacity,
  remaining,
  id,
}: Props): JSX.Element => {
  const classes = useStyles();
  const buttonRef = React.useRef<HTMLInputElement>();

  React.useEffect(() => {
    if (!ebWidgets) return;

    ebWidgets.createWidget({
      widgetType: 'checkout',
      eventId: id,
      modal: true,
      modalTriggerElementId: `event-${id}`,
      onOrderComplete: (params) => console.log('complete? ', params),
    });
  }, []);

  const handleOnClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };

  const remainingText =
    remaining >= 1 ? `${remaining} remaining` : 'fully booked';

  return (
    <Grid xs={12} sm={6} md={3} item>
      <Card className={classes.card}>
        <CardActionArea onClick={handleOnClick}>
          <CardMedia className={classes.media} image={logo.url} title={name} />
          <CardContent className={classes.cardContent}>
            <Typography className={classes.title}>{name}</Typography>
            <Typography className={classes.description}>
              {description}
            </Typography>
          </CardContent>
          <CardActions className={classes.cardActions}>
            <Typography className={classes.eventCapacity}>
              {remainingText}
            </Typography>
          </CardActions>
        </CardActionArea>
        <input
          ref={buttonRef}
          onClick={(e) => e.preventDefault()}
          id={`event-${id}`}
          style={{ display: 'none' }}
          type="button"
        />
      </Card>
    </Grid>
  );
};

export default EventCard;
