import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const styles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    eventsContainer: {
      minHeight: 500,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: spacing(4),
    },
  })
);

export default styles;
