import * as React from 'react';
import Layout from '../layouts/Layout';

import { EventList } from '../components/Events';
import { Container } from '@material-ui/core';

const Events = () => {
  return (
    <Layout title="Events">
      <div style={{ marginTop: 80 }} />
      <Container
        style={{ padding: '32px 8px 64px 8px', minHeight: 480 }}
        maxWidth="md"
      >
        <EventList />
      </Container>
    </Layout>
  );
};

export default Events;
