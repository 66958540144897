import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(({ palette, spacing }: Theme) =>
  createStyles({
    card: {},
    media: {
      height: 112,
    },
    cardContent: {
      minHeight: 140,
    },
    title: {
      fontWeight: 500,
      marginBottom: 4,
    },
    description: {
      fontSize: '11pt',
    },
    cardActions: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    eventCapacity: {
      display: 'block',
      // fontWeight: 500,
      textTransform: 'uppercase',
      fontSize: '9pt',
      color: palette.grey[500],
    },
    gridContainer: {
      height: '100%',
    },
    imageContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      width: spacing(25),
      position: 'relative',
      '& img': {
        height: 100,
        display: 'block',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: spacing(0.75),
        left: spacing(0.75),
        bottom: 0,
        right: 0,
      },
    },
    titleContainer: {
      flexGrow: 1,
      paddingLeft: spacing(2),
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingTop: spacing(),
      '& h5': {
        fontSize: '14pt',
      },
      '& h6': {
        fontSize: '10pt',
      },
    },
    ticketContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: spacing(11.25),
    },
    buttonContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      padding: spacing(0, 3),
    },
  })
);

export default useStyles;
